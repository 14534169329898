import React, { FC } from "react"

import {
  LABEL_DARK_FONT_TEXT,
  LABEL_LIGHT_FONT_TEXT,
} from "gatsby-theme-nurofen/src/utils/constants"

import { CardLabelProps } from "gatsby-theme-nurofen/src/components/common/CardLabel/model"

const CardLabel: FC<CardLabelProps> = ({ label, labelBgColor = "navy" }) => {
  return (
    <span
      className="nf-article-card__label"
      style={
        labelBgColor &&
        ({
          backgroundColor: `var(--${labelBgColor})`,
          color: `var(--${
            labelBgColor === "navy" || labelBgColor === "red"
              ? LABEL_LIGHT_FONT_TEXT
              : LABEL_DARK_FONT_TEXT
          })`,
        } as React.CSSProperties)
      }
    >
      {label}
    </span>
  )
}

export default CardLabel
