import React, { FC, useCallback, useEffect } from 'react';

import IconCustom from 'components/common/IconCustom';

import { NavigatorProps } from 'components/ControlledCarousel/model';
import './NextArrow.scss';

const NextArrow: FC<NavigatorProps> = ({
  controller,
  allItemsCount,
  ariaLabelNext,
  onEndCallback,
  onMoveCallback,
}) => {
  const currentSlide = controller?.state?.currentSlide || 0;
  const slidesToShow = controller?.state?.slidesToShow || 0;
  const isDisabled =
    allItemsCount - 1 === currentSlide || currentSlide >= allItemsCount - slidesToShow;

  useEffect(() => {
    if (onEndCallback && isDisabled) {
      onEndCallback();
    }
  }, [isDisabled]);

  const moveNext = useCallback(() => {
    controller?.next();
    if (onMoveCallback) {
      onMoveCallback();
    }
  }, [controller, onMoveCallback]);

  return (
    <button
      type="button"
      disabled={isDisabled}
      onClick={moveNext}
      className="nf-articles-navigator nf-articles-navigator__next nf-articles-navigator--disabled"
      aria-label={ariaLabelNext}
    >
      <IconCustom
        icon={`carousel_arrow ${isDisabled ? 'disabled-carousel-btn_icon' : 'carousel-btn_icon'}`}
      />
    </button>
  );
};
export default NextArrow;
