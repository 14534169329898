import React, { FC, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import NextArrow from 'components/common/NextArrow';
import PrevArrow from 'components/common/PrevArrow';

import { ControlledCarouselProps, CarouselItem } from './model';
import { GRADIENT_POSITION } from './constants';
import './ControlledCarousel.scss';

const ControlledCarousel: FC<ControlledCarouselProps> = ({
  bgGradientColor,
  children,
  classes = '',
  responsiveObj,
  navigatorsColor = 'navy',
  carouselControls,
  showDots = false,
  customDotsClass = '',
  infinite = false,
  customContainerClass = '',
  customDot = null,
  bothSidesGradient,
}) => {
  const [carouselSlidesToShow, setCarouselSlidesToShow] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [width, setWidth] = useState(0);
  const [isStaticMode, setIsLessContent] = useState<boolean>(true);
  const [navigatorProps, setNavigatorProps] = useState({
    controller: null,
    allItemsCount: 0,
    activeSlide: 0,
  });
  const [isShowGradient, setShowGradient] = useState<boolean>(false);
  const [gradientPosition, setGradientPosition] = useState(GRADIENT_POSITION.RIGHT);
  let thisCarousel: any | CarouselItem = null;
  const childrenNumber = children?.length || 0;

  useEffect(() => {
    Array.from(document.querySelectorAll('.react-multi-carousel-item')).forEach((item) => {
      Array.from(item.querySelectorAll('a, button')).forEach((child) => {
        item.matches('[aria-hidden="true"]')
          ? child?.setAttribute('tabindex', '-1')
          : child?.removeAttribute('tabindex');
      });
    });
  });

  useEffect(() => {
    const updateWidth = (e) => {
      if (!e?.target?.innerWidth) {
        return;
      }
      setWidth(e.target.innerWidth);
    };

    updateWidth(thisCarousel);
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    setShowGradient(!isStaticMode);
  }, [isStaticMode]);

  useEffect(() => {
    if (!childrenNumber || !thisCarousel) {
      return;
    }
    thisCarousel.goToSlide(0);
    setIsLessContent(childrenNumber <= carouselSlidesToShow);
    setNavigatorProps({
      controller: thisCarousel,
      allItemsCount: childrenNumber,
      activeSlide,
    });
    setGradientPosition(GRADIENT_POSITION.RIGHT);
  }, [width, carouselSlidesToShow]);

  const setActiveSlideHandler = useCallback((index: number) => {
    setActiveSlide(index);
  }, []);

  const handleEndNextControl = useCallback(() => {
    if (isStaticMode) {
      return;
    }
    setShowGradient(false);
    setGradientPosition(GRADIENT_POSITION.LEFT);
  }, [isStaticMode]);

  const handleMovePrevControl = useCallback(() => {
    if (isStaticMode || isShowGradient || gradientPosition === GRADIENT_POSITION.RIGHT) {
      return;
    }

    setShowGradient(true);
    setGradientPosition(GRADIENT_POSITION.RIGHT);
  }, [isStaticMode, isShowGradient]);

  const classesCarouselWrapper = classNames({
    [`carousel__wrapper`]: !isStaticMode,
  });

  const classesBgGradient = classNames('carousel__overlay', {
    [`${bgGradientColor}-default-bgLineGradient`]: !isStaticMode && bgGradientColor,
    'carousel__overlay--gradient-left':
      bothSidesGradient && gradientPosition === GRADIENT_POSITION.LEFT,
    'carousel__overlay--gradient-right':
      bothSidesGradient && gradientPosition === GRADIENT_POSITION.RIGHT,
  });

  return (
    <div className={classesCarouselWrapper}>
      {bothSidesGradient || isShowGradient ? <div className={classesBgGradient} /> : null}
      {childrenNumber > 0 ? (
        <div className={classes}>
          <Carousel
            showDots={showDots}
            customDot={customDot}
            dotListClass={customDotsClass}
            containerClass={customContainerClass}
            infinite={infinite}
            ssr
            centerMode={isStaticMode && childrenNumber === 1}
            beforeChange={setActiveSlideHandler}
            ref={(el) => {
              thisCarousel = el;
              setCarouselSlidesToShow(thisCarousel?.state.slidesToShow);
            }}
            partialVisible={!isStaticMode && childrenNumber > 1}
            className="nf-carousel"
            responsive={responsiveObj}
            arrows={false}
            itemClass={`${classes}__item`}
          >
            {children}
          </Carousel>
        </div>
      ) : null}
      {!isStaticMode && carouselControls && (
        <div
          className={`nf-carousel__navigators ${navigatorsColor}-default-text ${navigatorsColor}-active-text`}
        >
          <PrevArrow
            {...navigatorProps}
            onMoveCallback={handleMovePrevControl}
            ariaLabelPrev={carouselControls.ariaLabelPrev}
          />
          <NextArrow
            {...navigatorProps}
            onEndCallback={handleEndNextControl}
            ariaLabelNext={carouselControls.ariaLabelNext}
          />
        </div>
      )}
    </div>
  );
};

export const query = graphql`
  fragment FragmentResponsiveCarousel on IResponsiveCarousel {
    superLargeDesktop {
      properties {
        items
        partialVisibilityGutter
      }
    }
    desktop {
      properties {
        items
        partialVisibilityGutter
      }
    }
    tablet {
      properties {
        items
        partialVisibilityGutter
      }
    }
    mobile {
      properties {
        items
        partialVisibilityGutter
      }
    }
    smallMobile {
      properties {
        items
        partialVisibilityGutter
      }
    }
  }
`;

export default ControlledCarousel;
